// @flow
import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';

import Search from 'components/header/Search';
import MenuElement from 'components/header/MenuElement';
import MenuElementPlus from 'components/header/MenuElementPlus';
import { logoWidth } from 'constants/menuConstants';
import { type MenuElementType, nullMenuElement } from 'types/MenuElement';
import type { liensRsType } from 'types/Settings';
import { handleTagEvent } from 'utils/tagManagerUtils';

type StateProps = {
  clubName: string,
  liens_rs: liensRsType,
  logo: string,
  menuElements: MenuElementType[]
};

type Props = {
  onCloseMenu: Function,
  location: any
} & StateProps;

type ComponentState = {
  menuItems: Array<{ id: number, size: number }>,
  isMobile: boolean,
  hiddenMenuItems: Array<number>,
  menuElements: Array<MenuElement>,
  menuLinkX: number,
  hiddenSubMenu: boolean,
  isWideScreen: boolean
};

class Menu extends PureComponent<Props, ComponentState> {
  _menuElements: Array<?MenuElement>;
  _menu: ?HTMLElement;
  _menuRight: ?HTMLElement;
  _menuLink: ?HTMLElement;
  _frameId: string;
  state: ComponentState = {
    menuItems: [],
    isWideScreen: false,
    isMobile: false,
    hiddenMenuItems: [],
    menuElements: [],
    menuLinkX: 0,
    hiddenSubMenu: false
  };

  static defaultProps = {
    menuElements: []
  };

  constructor(props: Props) {
    super(props);
    this._menuElements = [];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  resetMenuElementRef = (c: ?MenuElement) => {
    this._menuElements = [];
    this.addMenuElementRef(c);
  };

  addMenuElementRef = (c: ?MenuElement) => {
    this._menuElements.push(c);
  };

  isDisplayed = (id: number) => {
    const { hiddenMenuItems } = this.state;

    return !hiddenMenuItems.includes(id);
  };

  hiddenTemporary = () => {
    this.setState({ hiddenSubMenu: true });

    setTimeout(() => {
      this.setState({ hiddenSubMenu: false });
    }, 500);
  };

  updateDimensions() {
    const { isMobile, hiddenMenuItems, menuLinkX: XlinkState } = this.state;
    const menu = this._menu;
    const menuRight = this._menuRight;
    const menuLink = this._menuLink;
    const isWideScreen = window.innerWidth > 1280;

    this.setState({ isWideScreen });

    if (menu && menuRight && menuLink) {
      const menuLinkBounds = menuLink.getBoundingClientRect();
      const menuLinkX = menuLinkBounds.left;

      if (menuLinkX !== XlinkState) this.setState({ menuLinkX });
      const maxWidth = menu.clientWidth - (isWideScreen ? 120 : 80);
      const width_logo = logoWidth;

      const width_menu_links =
        this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) + this._menuElements.length * 12;

      const width_menu_right = menuRight.offsetWidth;
      const totalWidth = width_menu_links + width_menu_right + width_logo + 100;

      let currentWidth = totalWidth;
      let deleted = [];
      if (maxWidth >= 1000) {
        for (let i = this._menuElements.length; i > 0; i--) {
          if (currentWidth > maxWidth - logoWidth) {
            if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize();
            deleted = [...deleted, i];
          }
        }
        if (isMobile) this.setState({ isMobile: false });
      } else {
        if (!isMobile) this.setState({ isMobile: true });
      }
      if ((deleted.length > 0 || hiddenMenuItems.length > 0) && JSON.stringify(deleted) !== JSON.stringify(hiddenMenuItems)) {
        this.setState({ hiddenMenuItems: deleted });
      }
    }
  }

  hiddenAndClose = () => {
    const { hiddenTemporary } = this;
    const { onCloseMenu } = this.props;
    hiddenTemporary();
    if (onCloseMenu) onCloseMenu();
  };

  renderSubMenu = (subMenus: Array<Object>): any => {
    return subMenus.map((subMenu, index) => {
      if (subMenu.type === 'custom') {
        const subMenus = subMenu.items ? subMenu.items : [];
        if (subMenu.url === '#') {
          if (subMenus.length > 0) {
            const subMenusRender = [];
            if (subMenus.length > 7) {
              let subMenusSlice = subMenus.slice(0, 6);
              subMenusRender.push(subMenusSlice);
              subMenusSlice = subMenus.slice(7, 13);
              subMenusRender.push(subMenusSlice);
            } else {
              subMenusRender.push(subMenus);
            }
            const classe = this;
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
                {subMenusRender.map(function(subMenus, i) {
                  return (
                    <ul key={i}>
                      <>{classe.renderSubMenu(subMenus)}</>
                    </ul>
                  );
                })}
              </li>
            );
          } else {
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
              </li>
            );
          }
        } else {
          if (subMenu.type === 'custom') {
            return (
              <li key={index}>
                <a href={`${subMenu.url}`} target="_blank" rel="noopener noreferrer">
                  {subMenu.title}
                </a>
              </li>
            );
          }
          return (
            <li key={index}>
              <Link to={`${subMenu.slug_complet}`} target="_blank" onClick={() => this.hiddenAndClose()}>
                {subMenu.title}
              </Link>
            </li>
          );
        }
      } else if (subMenu.type === 'post_type' || subMenu.type === 'taxonomy') {
        return (
          <li key={index}>
            <Link to={`${subMenu.slug_complet}`} onClick={() => this.hiddenAndClose()}>
              {subMenu.title}
            </Link>
          </li>
        );
      }
      return null;
    });
  };

  renderChidrenMenuElement = (subMenus: Array<Object>) => {
    const { hiddenSubMenu } = this.state;

    if (!hiddenSubMenu) {
      return <>{this.renderSubMenu(subMenus)}</>;
    }

    return null;
  };

  renderMenuElement = () => {
    const {
      menuElements,
      onCloseMenu,
      location: { pathname }
    } = this.props;
    const { isMobile, menuLinkX, isWideScreen } = this.state;
    let indexMenuElements = 0;

    if (!menuElements) return [];
    let renderedMenu = menuElements.map((menu, index) => {
      const subMenus = menu.items || [];
      if (menu.type === 'post_type' || menu.type === 'custom') {
        if (menu.classes === 'bouton') {
          return null;
        }
        const url = pathname.split('/');
        if (subMenus.length > 0) {
          let col = false;
          subMenus.forEach(subMenu => {
            if (subMenu.items && subMenu.items.length > 0) {
              col = true;
            }
          });
          const menuEl = (
            <MenuElement
              url={menu.object_slug ? `/${menu.object_slug}` : menu.url}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={isMobile}
              isWideScreen={isWideScreen}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={index}
              col={!!col}
              isActive={url[1] === menu.object_slug && menu.type !== 'custom'}
              onCloseMenu={onCloseMenu}
            >
              {this.renderChidrenMenuElement(subMenus)}
            </MenuElement>
          );
          indexMenuElements++;
          return menuEl;
        } else {
          const menuEl = (
            <MenuElement
              url={menu.object_slug !== '' ? `/${menu.object_slug}` : menu.url}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isWideScreen={isWideScreen}
              isMobile={isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={index}
              isActive={url[1] === menu.object_slug && menu.type !== 'custom'}
              onCloseMenu={onCloseMenu}
            />
          );
          indexMenuElements++;
          return menuEl;
        }
      }
      return null;
    });

    return renderedMenu;
  };

  renderSocialNetworkLinks = () => {
    const {
      liens_rs: { fb_lien, twitter_lien, instagram_lien, youtube_lien, flickr_lien, linkedin_lien },
      clubName
    } = this.props;

    return (
      <div className="menu__social">
        {fb_lien && (
          <a
            className="link-icon"
            href={fb_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Facebook de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-facebook" />
          </a>
        )}
        {twitter_lien && (
          <a
            className="link-icon"
            href={twitter_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Twitter de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-twitter" />
          </a>
        )}
        {instagram_lien && (
          <a
            className="link-icon"
            href={instagram_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Instagram de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-instagram" />
          </a>
        )}
        {youtube_lien && (
          <a
            className="link-icon"
            href={youtube_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Youtube de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-youtube" />
          </a>
        )}
        {flickr_lien && (
          <a
            className="link-icon"
            href={flickr_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Flickr de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-flickr" />
          </a>
        )}
        {linkedin_lien && (
          <a
            className="link-icon"
            href={linkedin_lien}
            target="_blank"
            rel="noopener noreferrer"
            title={`Se rendre sur la page Linkedin de ${clubName} (nouvel onglet)`}
          >
            <i className="icon icon-linkedin" />
          </a>
        )}
      </div>
    );
  };

  renderSearchContactButton = () => {
    const { menuElements, onCloseMenu, clubName } = this.props;
    const { slug_complet, title } = menuElements.filter(el => el.classes === 'bouton')[0] || nullMenuElement;
    return (
      <div className="menu__right" ref={c => (this._menuRight = c)}>
        {slug_complet && title && (
          <Link
            to={slug_complet}
            title={title}
            className="btn btn--primary menu__club"
            onClick={handleTagEvent('barre_navigation', 'clic_contacter_club', clubName, onCloseMenu)}
          >
            <span>{title}</span>
          </Link>
        )}
        <Search clubName={clubName} />
      </div>
    );
  };

  renderLogo = () => {
    const {
      location: { pathname },
      clubName,
      logo
    } = this.props;
    if (pathname === '/') {
      return (
        <h1 className="header__club-logo">
          <span>{clubName}</span>
          <img alt={`Logo ${clubName}`} className="menu__logo" src={logo} />
        </h1>
      );
    }
    return (
      <Link className="header__club-logo" to="/">
        <img alt={`Logo ${clubName}`} className="menu__logo" src={logo} />
      </Link>
    );
  };

  render() {
    const { onCloseMenu, logo, menuElements } = this.props;
    const { isMobile, menuLinkX, hiddenMenuItems, isWideScreen } = this.state;

    // MenuElementPlus
    const hiddenMenuElements = hiddenMenuItems.map(id => {
      return this._menuElements[id];
    });
    if (hiddenMenuElements.length > 0) {
      hiddenMenuElements.shift();
      const menuPlus = menuElements.find(menu => menu.type === 'custom' && menu.title === 'Plus');
      if (menuPlus) {
        const menuPlusChildren = menuElements.filter(item => item.parent === menuPlus.id);
        menuPlusChildren.forEach((menu, index) => {
          hiddenMenuElements.splice(
            0,
            0,
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isWideScreen={isWideScreen}
              isMobile={isMobile}
              id={menu.id}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={index}
              onCloseMenu={onCloseMenu}
            />
          );
        });
      }
    }

    return (
      <nav className="menu" ref={c => (this._menu = c)}>
        {logo && this.renderLogo()}
        <ul className="menu__link" ref={c => (this._menuLink = c)}>
          {this.renderMenuElement()}

          <MenuElementPlus
            hiddenMenuElements={hiddenMenuElements}
            isMobile={isMobile}
            id={10}
            isDisplayed={this.isDisplayed}
            menuX={menuLinkX}
            text="Plus"
          />
        </ul>

        {this.renderSocialNetworkLinks()}

        {this.renderSearchContactButton()}

        <i className="icon icon-close menu__close js-showMenu" role="button" tabIndex={0} onClick={onCloseMenu} aria-label="Fermer" />
      </nav>
    );
  }
}

export default withRouter(Menu);
