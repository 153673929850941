// @flow

import type { ApolloResponseType } from 'types/ApolloType';

import { mergeIntoDefault } from 'utils/objectUtils';
import { formatDateWithMonthYear } from 'utils/dateUtils';

import {
  nullResultCalendarContainer,
  nullCompetitionResult,
  nullResult,
  nullResultDetail,
  nullStructure,
  nullResultCalendar,
  type CompetitionResultType,
  type ResultDetailType,
  type ResultType,
  type StructureType,
  type ResultCalendarType,
  type ResultCalendarContainerType,
} from 'types/Result';

const convertResultFromGraph = (rawGraphResponse: ApolloResponseType): CompetitionResultType[] => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return [nullCompetitionResult];
  }

  return rawGraphResponse.data.Rencontres.reduce((acc, current) => {
    const {
      Competition: { id: competitionId, nom: competitionNom, identifiant: competitionIdentifiant, Saison: saison },
      Journee: { id: journeeId, nom: journeeNom },
      RencontreResultatVisiteuse: rawResultVisiteur,
      RencontreResultatLocale: rawResultLocal,
      CompetitionEquipeLocale: rawStructureLocal,
      CompetitionEquipeVisiteuse: rawStructureVisiteur,
      Etat: { nom: etat },
      etatMixte,
      id,
      dateOfficielle,
      dateEffective,
    } = current;

    const competitionIndex = acc.findIndex((comp) => comp.id === competitionId);

    const resultLocal: ResultDetailType = mergeIntoDefault(nullResultDetail, { ...rawResultLocal });
    const resultVisiteur: ResultDetailType = mergeIntoDefault(nullResultDetail, { ...rawResultVisiteur });

    const localStructure: StructureType = mergeIntoDefault(nullStructure, rawStructureLocal.Structure
      ? { ...rawStructureLocal.Structure, isRegroupement: false, nom: rawStructureLocal.nom.replace(/\(.*\)/g, '') }
      : { ...rawStructureLocal.Regroupement, isRegroupement: true, nom: rawStructureLocal.nom.replace(/\(.*\)/g, '') });

    const visiteurStructure: StructureType = mergeIntoDefault(nullStructure, rawStructureVisiteur.Structure
      ? { ...rawStructureVisiteur.Structure, isRegroupement: false, nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, '') }
      : { ...rawStructureVisiteur.Regroupement, isRegroupement: true, nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, '') });

    const result: ResultType = mergeIntoDefault(nullResult, {
      id,
      resultVisiteur,
      resultLocal,
      localStructure,
      visiteurStructure,
      etat: (etatMixte === 'En direct') ? 'En direct' : etat,
      dateOfficielle: new Date(dateOfficielle),
      dateEffective: new Date(dateEffective),
      competitionId,
      competitionNom,
      competitionIdentifiant,
      journeeId,
      journeeNom,
    });


    const journee = {
      id: journeeId,
      nom: journeeNom,
      competitionId: competitionId,
      competitionNom: competitionNom,
      date: new Date(dateEffective),
      rencontres: [result],
    };

    if (competitionIndex !== -1) {
      const journeeIndex = acc[competitionIndex]
        .journees
        .findIndex((jour) => jour.id === journeeId);

      if (journeeIndex !== -1) {
        acc[competitionIndex].journees[journeeIndex].rencontres.push(result);
      } else {
        acc[competitionIndex].journees.push(journee);
      }
    } else {
      acc.push({
        id: competitionId,
        nom: competitionNom,
        saison: saison,
        identifiant: competitionIdentifiant,
        journees: [journee]
      });
    }

    return acc;
  }, []);
};

const convertResultCalendarFromGraph = (rawGraphResponse: ApolloResponseType): ResultCalendarContainerType => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return nullResultCalendarContainer;
  }

  if (rawGraphResponse.data.Rencontres.length === 0) {
    return {};
  }

  const response = rawGraphResponse.data.Rencontres.sort((a, b) => a.dateEffective > b.dateEffective ? 1 : a.dateEffective < b.dateEffective ? -1 : 0 ).reduce((acc, current) => {
    if (!current.Terrain || (new Date(current.dateEffective) < new Date())) {
      return acc;
    }

    const {
      Competition: { 
        id: competitionId,
        nom: competitionNom,
        ClasseAge: { 
          code: classeAgeCode,
          Sexe: { code: classeAgeSexeCode }
        },
        saison,
        Famille : {
          nom: competitionFamille
        }
      },
      Journee: { id: journeeId, nom: journeeNom },
      CompetitionEquipeLocale: rawStructureLocal,
      CompetitionEquipeVisiteuse: rawStructureVisiteur,
      Etat: { nom: etat },
      etatMixte,
      id,
      dateOfficielle,
      dateEffective,
      Terrain: {
        id: terrainId,
        nom: terrainNom,
        Adresse: {
          localite,
          codePostal,
          voie,
          position,
        }
      }
    } = current;

    const localStructure: StructureType = mergeIntoDefault(nullStructure, rawStructureLocal.Structure
      ? { ...rawStructureLocal.Structure, isRegroupement: false, nom: rawStructureLocal.nom.replace(/\(.*\)/g, '') }
      : { ...rawStructureLocal.Regroupement, isRegroupement: true, nom: rawStructureLocal.nom.replace(/\(.*\)/g, '') });

    const visiteurStructure: StructureType = mergeIntoDefault(nullStructure, rawStructureVisiteur.Structure
      ? { ...rawStructureVisiteur.Structure, isRegroupement: false, nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, '') }
      : { ...rawStructureVisiteur.Regroupement, isRegroupement: true, nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, '') });

    const address = `${voie} ${codePostal} ${localite}`;

    const result: ResultCalendarType = mergeIntoDefault(nullResultCalendar, {
      id,
      terrain: {
        id: terrainId,
        nom: terrainNom,
        localite,
        address: address.replace(/null/, '').trim(),
        coordinates: (position && position.coordinates) || null,
      },
      localStructure,
      visiteurStructure,
      etat: (etatMixte === 'En direct') ? 'En direct' : etat,
      dateOfficielle: new Date(dateOfficielle),
      dateEffective: new Date(dateEffective),
      competitionId,
      journeeId,
      competition: {
        id: competitionId,
        nom: competitionNom,
        famille: competitionFamille,
        age: classeAgeCode,
        sexe: classeAgeSexeCode,
        saison: saison
      },
      journee: {
        id: journeeId,
        nom: journeeNom,
      },
    });

    const dateFormated = formatDateWithMonthYear(new Date(dateEffective));

    if (Object.prototype.hasOwnProperty.call(acc, dateFormated)) {
      acc[dateFormated].push(result);
    } else {
      acc[dateFormated] = [result];
    }


    return acc;
  }, {});

  return response;
};

export { convertResultFromGraph, convertResultCalendarFromGraph };
