// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import TopBar from 'containers/header/TopBarContainer';
import MenuContainer from 'containers/header/MenuContainer';
import HeaderMobile from 'containers/header/HeaderMobileContainer';
import { setBodyStyle, resetBodyStyle } from 'utils/bodyUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  location: any
};

type ComponentState = {
  isMobileMenuOpen: boolean,
  isMobileMenuSticky: boolean,
  lastScrollPosition: number,
};

class Header extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    isMobileMenuOpen: false,
    isMobileMenuSticky: false,
    lastScrollPosition: 0,
  };

  onScrollPosition = () => {
    const { lastScrollPosition, isMobileMenuSticky } = this.state;
    const screenWidth = window.innerWidth;
    const scrollPosition = window.scrollY;

    if (screenWidth < 1080) {
      if (lastScrollPosition > scrollPosition && scrollPosition > 250) {
        if (!isMobileMenuSticky) {
          this.setState({ isMobileMenuSticky: true });
        }
      } else {
        if (isMobileMenuSticky) {
          this.setState({ isMobileMenuSticky: false });
        }
      }
    } else {
      if (screenWidth >= 1080 && isMobileMenuSticky) {
        this.setState({ isMobileMenuSticky: false });
      }
    }

    this.setState({ lastScrollPosition: scrollPosition });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollPosition);
  }

  onToggleMenu = () => {
    if (this.state.isMobileMenuOpen) this.onCloseMenu();
    else this.onOpenMenu();
  };

  onOpenMenu = () => {
    setBodyStyle({
      position: 'fixed',
      width: '100%',
      top: 0
    });
    this.setState({ isMobileMenuOpen: true });
  };

  onCloseMenu = () => {
    resetBodyStyle();
    this.setState({ isMobileMenuOpen: false });
  };
 
  render() {
    const { location } = this.props;
    const { isMobileMenuOpen, isMobileMenuSticky } = this.state;
    const classNamesHeaderWrapper = dynamicClassName('header__wrapper');
    isMobileMenuOpen && classNamesHeaderWrapper.add('is-visible');
    const classNamesHeader = dynamicClassName('header');
    const header_over =
      !location.pathname.match('actualites/.*/.*') &&
      !location.pathname.match('resultats-de-recherche/') &&
      !location.pathname.match('recherche') &&
      !location.pathname.match(/evenements\/[a-z]/g) && 
      !location.pathname.match('--partenaire');
    header_over && classNamesHeader.add('header--over');
    !isMobileMenuSticky && header_over && classNamesHeader.add('header--transparent');
    isMobileMenuSticky && classNamesHeader.add('header--fixed');

    return (
      <>
        <header className={classNamesHeader.build()}>
          <div className={classNamesHeaderWrapper.build()}>
            <TopBar />
            <MenuContainer onCloseMenu={this.onCloseMenu} />
          </div>
          <HeaderMobile
            isMenuOpen={isMobileMenuOpen}
            headerOver={header_over && !isMobileMenuSticky}
            onToggleMenu={this.onToggleMenu}
            onCloseMenu={this.onCloseMenu} />
        </header>
      </>
    );
  }
}

export default withRouter(Header);
